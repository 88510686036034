<template>
  <v-container fluid>
    <v-data-table
      :headers="header"
      :items="tariffs"
      item-key="id"
      class="elevation-1"
      :loading="tariffs_loading"
      :server-items-length="total_tariffs"
      :options.sync="options"
      :items-per-page="25"
      :footer-props="{
        'items-per-page-options': [15, 25, 50, 100, -1],
      }"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-toolbar dense flat>
          <v-text-field
            v-model="search"
            hide-details
            label="Поиск по названию"
            prepend-icon="mdi-magnify"
            single-line
          ></v-text-field>

          <v-btn icon @click="add_item">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </v-toolbar>
        <v-dialog v-model="dialog" max-width="500px" @click:outside="close">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Название тарифа*"
                      hint="Название позволяют отличать тарифы"
                      :rules="[required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-if="operates_from_enabled"
                      v-model="editedItem.operates_from"
                      label="Время начала*"
                      v-mask="timeRangeMask"
                      :rules="[required]"
                      placeholder="00:00-23:59"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-if="operates_from_enabled"
                      v-model="editedItem.operates_to"
                      :rules="[required]"
                      v-mask="timeRangeMask"
                      placeholder="00:00-23:59"
                      label="Время окончания*"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model.number="editedItem.cost"
                      :rules="[required]"
                      step="0.1"
                      type="number"
                      min="0"
                      label="Цена по тарифу*"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-checkbox
                      v-model="editedItem.fixed"
                      label="Фиксированный, цена за сессию"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <h3>Активные станции</h3>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Номер</th>
                        <th class="text-left">Адресс</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="cp in editedItem.charge_points" :key="cp.id">
                        <td>{{ cp.point_number }}</td>
                        <td>{{ cp.address }}</td>
                        <td>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="mr-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="remove_cp(cp)"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                            <span>Удалить привязку к станции</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="close"> Отмена </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Редактировать</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small v-bind="attrs" v-on="on"> mdi-delete </v-icon>
          </template>
          <span>{{ remove_tooltip(item) }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export function timeMask(value) {
  const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
  const minutes = [/[0-5]/, /[0-9]/];
  return value.length > 2 ? [...hours, ":", ...minutes] : hours;
}

export function timeRangeMask(value) {
  const numbers = value.replace(/[^0-9]/g, "");
  if (numbers.length > 4) {
    return [...timeMask(numbers.substring(0, 4))];
  }
  return [...timeMask(numbers)];
}

export default {
  name: "List_tariff",

  data() {
    return {
      search: "",
      calories: "",
      editedIndex: -1,
      dialog: false,
      operates_from_enabled: true,
      required: (v) => !!v || "Обязателено для заполнения",
      options: {
        sortBy: ["id"],
        sortDesc: [false],
      },
      editedItem: {
        name: "",
        operates_from: "",
        operates_to: "",
        cost: 0,
        fixed: false,
      },
      defaultItem: {
        name: "",
        operates_from: "",
        operates_to: "",
        cost: 0,
        fixed: false,
      },
      timeRangeMask,
    };
  },
  created() {
    this.$store.dispatch("load_tariffs", this.options);
  },
  watch: {
    // options: {
    //   handler() {
    //     this.$store.dispatch("load_tariffs", this.options);
    //   },
    //   deep: true,
    // },
    params: {
      handler() {
        this.$store.dispatch("load_tariffs", this.params);
      },
      deep: true,
    },
  },
  computed: {
    params() {
      return {
        ...this.options,
        query: this.search,
      };
    },
    formTitle() {
      return this.editedIndex === -1 ? "Добавить тариф" : "Изменить тариф";
    },
    header() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Название",
          align: "start",
          sortable: true,
          filterable: true,
          value: "name",
        },
        {
          text: "Действует с",
          align: "start",
          sortable: true,
          value: "operates_from",
        },
        {
          text: "Действует до",
          align: "start",
          sortable: true,
          value: "operates_to",
        },
        {
          text: "Цена",
          align: "start",
          sortable: true,
          value: "cost",
        },
        {
          text: "Добавил",
          align: "start",
          sortable: true,
          value: "creator_id",
        },
        {
          text: "Создан",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        { text: "", value: "actions", sortable: false },
      ];
    },
    tariffs() {
      return this.$store.getters.TARIFFS;
    },
    tariffs_loading() {
      return this.$store.getters.LOADING_TARIFFS;
    },
    total_tariffs() {
      return this.$store.getters.TOTAL_TARIFFS;
    },
  },
  methods: {
    add_item() {
      this.editItem(this.defaultItem);
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search) !== -1
      );
    },
    remove_tooltip(item) {
      if (item.charge_points.length > 0) {
        return "Нельзя удалять действующие тарифы";
      }
      return "Удалить тариф";
    },
    editItem(item) {
      this.dialog = true;
      this.editedIndex = this.tariffs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // this.$set(this.editedItem, "operates_from", item.operates_from);
      // this.$set(this.editedItem, "operates_to", item.operates_to);
      // this.$set(this.editedItem, "cost", item.cost);
      // this.$set(this.editedItem, "fixed", item.fixed);
      // this.$set(this.editedItem, "name", item.name);
      this.operates_from_enabled = false;
      this.$nextTick(() => {
        this.operates_from_enabled = true;
      });
    },
    deleteItem(item) {
      if (item.charge_points.length > 0) {
        return;
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.operates_from_enabled = false;
        this.$nextTick(() => {
          this.operates_from_enabled = true;
        });
      });
    },
    save() {
      if (this.editedIndex > -1) {
        this.$store.dispatch("update_tariff", this.editedItem).then(() => {
          this.$store.dispatch("load_tariffs", this.params);
        });
      } else {
        this.$store.dispatch("create_tariff", this.editedItem).then(() => {
          this.$store.dispatch("load_tariffs", this.params);
        });
      }
      this.close();
    },
    remove_cp(cp) {
      this.$store
        .dispatch("remove_point_tariff", {
          point_number: cp.point_number,
          tariff_id: this.editedItem.id,
        })
        .then(() => {
          this.$store.dispatch("load_tariffs", this.params);
        });
    },
  },
};
</script>

<style scoped lang="scss">
#map {
  height: 800px;
}
</style>
